:root {
    --font-family: 'Mycall Gelion, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';

    --purpur-color-background-secondary: #0000F0;
    --purpur-color-text-interactive-primary-active: #00F000;
    --purpur-color-text-interactive-primary-hover: #F000F0;

    --purpur-color-text-interactive-on-expressive: #ffffff;
    --purpur-color-background-interactive-expressive: #110D60;
    --purpur-color-background-interactive-expressive-hover: #0b083e;

    --purpur-color-background-interactive-primary: #110D60;
    --purpur-color-text-interactive-on-primary: #ffffff;

    --purpur-color-text-interactive-primary: #2A2A2B;
    --purpur-color-background-interactive-primary-hover: #0b083e;


    /* Secondary */
    --purpur-color-background-interactive-transparent: transparent;
    --purpur-color-background-interactive-transparent-hover: #ffffff;
    --purpur-color-border-interactive-primary: #2A2A2B;
    --purpur-color-background-interactive-transparent-active: #433be4;


    /* Disabled */
    --purpur-color-background-interactive-disabled: #433be4;
    --purpur-color-text-weak: #ffffff;


    --purpur-color-text-default: #222222;
    --purpur-color-border-interactive-subtle: #f00;
    --purpur-color-border-interactive-primary-hover: #0f0;

    --purpur-color-background-tone-on-tone-secondary: #736ee0;
}
